import { Redirect } from "react-router-dom";
import { rootPath, userType } from "../../lib";

type propTypes = {
  Component: React.ElementType;
  user: userType | null;
};

export function Unauthorized(props: propTypes) {
  const { Component, user } = props;
  return !user ? <Component /> : <Redirect to={rootPath} />;
}

export function Authorized(props: propTypes) {
  const { Component, user } = props;
  return user ? <Component /> : <Redirect to={rootPath} />;
}
