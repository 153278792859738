import { onAuthStateChanged, User } from "firebase/auth";
import { useState, useEffect, createContext, useContext } from "react";
import { firebaseAuth } from "./firebase";

export type userType = User;

type contextTypes = {
  user: userType | null;
  loading: boolean;
};

const UserContext = createContext<contextTypes>({
  user: null,
  loading: true,
});

type propTypes = {
  children: React.ReactNode;
};

export function UserProvider({ children }: propTypes) {
  const [user, setUser] = useState<userType | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      setUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  return (
    <UserContext.Provider value={{ user, loading }}>
      {children}
    </UserContext.Provider>
  );
}

export const useUser = () => useContext(UserContext);

export function useAuth() {
  const { user } = useUser();
  return { user: user as userType };
}
