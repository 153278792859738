export default function Spinner() {
  return (
    <div
      className="uk-flex uk-flex-middle uk-flex-center"
      data-uk-height-viewport="expand: true; offset-top: true; offset-bottom: true"
    >
      <div data-uk-spinner="ratio: 3" />
    </div>
  );
}
