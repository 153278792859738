import { inputMax, inputMin } from "../../lib";

type propTypes = {
  type: "text" | "email" | "url";
  value: string;
  required?: boolean;
  disabled?: boolean;
  onChange: (value: string) => void;
};

export default function Input(props: propTypes) {
  return (
    <input
      className="uk-input"
      style={{ width: 320 }}
      type={props.type}
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)}
      minLength={inputMin}
      maxLength={inputMax}
      required={props.required}
      disabled={props.disabled}
    />
  );
}
