import { useState } from "react";
import { inputMax, inputMin, validateEmail } from "../../lib";
import { Button, Grid, Icon, Margin, Tooltip } from "../shared";

type propTypes = {
  status: string;
  message: string;
  onValidated: ({ EMAIL, NAME }: { EMAIL: string; NAME: string }) => void;
};

export default function Subscribe(props: propTypes) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (validateEmail(email)) {
      props.onValidated({ EMAIL: email, NAME: name });
    }
  }

  return (
    <form className="uk-form-stacked" onSubmit={handleSubmit}>
      <Margin small>
        <div className="uk-form-controls uk-inline">
          <button
            type="button"
            className="uk-form-icon"
            data-uk-icon="user"
            tabIndex={-1}
          />
          <input
            type="text"
            className="uk-input uk-form-small"
            value={name}
            onChange={(e) => setName(e.target.value)}
            minLength={inputMin}
            maxLength={inputMax}
            placeholder="Your name"
            required
          />
        </div>
      </Margin>

      <Margin small>
        <div className="uk-form-controls uk-inline">
          <button
            type="button"
            className="uk-form-icon"
            data-uk-icon="mail"
            tabIndex={-1}
          />
          <input
            type="email"
            className="uk-input uk-form-small"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            minLength={inputMin}
            maxLength={inputMax}
            placeholder="Your email"
            required
          />
        </div>
      </Margin>

      <Margin small>
        <Grid gap="small" vertical="middle" horizontal="right">
          <div>
            {props.status === "sending" && (
              <Tooltip text={props.message}>
                <span data-uk-spinner />
              </Tooltip>
            )}
            {props.status === "error" && (
              <Tooltip text={props.message}>
                <Icon icon="warning" />
              </Tooltip>
            )}
            {props.status === "success" && (
              <Tooltip text={props.message}>
                <Icon icon="check" />
              </Tooltip>
            )}
          </div>
          <div>
            <Button submit variant="default" size="small">
              subscribe
            </Button>
          </div>
        </Grid>
      </Margin>
    </form>
  );
}
