import { BrowserRouter } from "react-router-dom";
import Layout from "./components/layout";
import Routes from "./components/routes";
import { UserProvider } from "./lib";

export default function App() {
  return (
    <BrowserRouter>
      <UserProvider>
        <Layout>
          <Routes />
        </Layout>
      </UserProvider>
    </BrowserRouter>
  );
}
