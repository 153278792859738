import { Link, useLocation } from "react-router-dom";
import {
  useUser,
  authSignOut,
  authPath,
  logoPath,
  rootPath,
  createPath,
  supportPath,
  aboutPath,
  activeClass,
} from "../../lib";

export default function Navbar() {
  const { pathname } = useLocation();
  const { user } = useUser();

  return (
    <nav className="uk-navbar-container" data-uk-navbar>
      <div className="uk-navbar-left">
        <div className="uk-navbar-item">
          <Link to={rootPath}>
            <img src={logoPath} alt="logo" width="48" height="48" />
          </Link>
        </div>
      </div>

      <div className="uk-navbar-right">
        <ul className="uk-navbar-nav" id="nav">
          {user && (
            <>
              <li className={activeClass(pathname === createPath)}>
                <Link to={createPath}>list PWA</Link>
              </li>
              <li>
                <a href="#signout" onClick={authSignOut}>
                  sign out
                </a>
              </li>
            </>
          )}
          {!user && (
            <>
              <li className={activeClass(pathname === authPath)}>
                <Link to={authPath}>sign in</Link>
              </li>
              <li className={activeClass(pathname === supportPath)}>
                <Link to={supportPath}>support</Link>
              </li>
              <li className={activeClass(pathname === aboutPath)}>
                <Link to={aboutPath}>About</Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
}
