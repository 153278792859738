import {
  QuerySnapshot,
  QueryDocumentSnapshot,
  DocumentData,
  DocumentSnapshot,
  Timestamp,
} from "firebase/firestore";

export type docType = {
  id: string;
  created: Timestamp;
};

function wrap<T extends docType>(
  snapshot: QueryDocumentSnapshot<DocumentData>
) {
  const id = snapshot.id;
  const data = snapshot.data();
  const created = data.created;
  return { ...data, id, created } as T;
}

export function wrapDoc<T extends docType>(
  snapshot: DocumentSnapshot<DocumentData>
) {
  if (snapshot.exists()) return wrap(snapshot) as T;
  return null;
}

export function wrapDocs<T extends docType>(
  snapshot: QuerySnapshot<DocumentData>
) {
  const docs: T[] = [];
  snapshot.forEach((doc) => {
    docs.push(wrap(doc));
  });
  return docs;
}
