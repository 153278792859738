import { Link, useLocation } from "react-router-dom";
import { aboutPath, activeClass, supportPath } from "../../lib";

export default function Right() {
  const { pathname } = useLocation();
  return (
    <ul className="uk-nav uk-nav-default">
      <li className="uk-nav-header">Organization</li>
      <li className={activeClass(pathname === aboutPath)}>
        <Link to={aboutPath}>About Us</Link>
      </li>
      <li className={activeClass(pathname === supportPath)}>
        <Link to={supportPath}>Support Us</Link>
      </li>
      <li className={activeClass(pathname === "#")}>
        <Link to="#">Privacy Policy</Link>
      </li>
      <li className={activeClass(pathname === "#")}>
        <Link to="#">Terms of Service</Link>
      </li>
    </ul>
  );
}
