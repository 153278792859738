type propTypes = {
  gap?: "small" | "collapse";
  horizontal?: "left" | "center" | "right" | "between" | "around";
  vertical?: "stretch" | "top" | "middle" | "bottom";
  direction?: "row" | "column";
  wrap?: "wrap" | "nowrap";
  children: React.ReactNode;
};

export default function Grid(props: propTypes) {
  let className = "uk-grid";
  if (props.gap) className += ` uk-grid-${props.gap}`;
  if (props.horizontal) className += ` uk-flex-${props.horizontal}`;
  if (props.vertical) className += ` uk-flex-${props.vertical}`;
  if (props.direction) className += ` uk-flex-${props.direction}`;
  if (props.wrap) className += ` uk-flex-${props.wrap}`;

  return (
    <div className={className} data-uk-grid>
      {props.children}
    </div>
  );
}
