import Icon from "./Icon";
import Tooltip from "./Tooltip";

type propTypes = { text: string };

export default function Info(props: propTypes) {
  return (
    <Tooltip text={props.text}>
      <Icon icon="question" />
    </Tooltip>
  );
}
