type sectionTypes = {
  children: React.ReactNode;
};

Card.Header = function Header(props: sectionTypes) {
  return <div className="uk-card-header">{props.children}</div>;
};

Card.Body = function Body(props: sectionTypes) {
  return <div className="uk-card-body">{props.children}</div>;
};

Card.Footer = function Footer(props: sectionTypes) {
  return <div className="uk-card-footer">{props.children}</div>;
};

type propTypes = {
  children:
    | React.ReactElement<sectionTypes>
    | Array<React.ReactElement<sectionTypes>>;
};

export default function Card(props: propTypes) {
  return (
    <div className="uk-card uk-card-default uk-card-hover">
      {props.children}
    </div>
  );
}
