import { useEffect, useState } from "react";
import { appType, appsGet } from "../lib";

export default function useAppGet(id: string) {
  const [app, setApp] = useState<appType | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      const unsubscribe = appsGet(id, (app) => {
        setApp(app);
        setLoading(false);
      });
      return unsubscribe;
    } else {
      setApp(null);
      setLoading(false);
      return undefined;
    }
  }, [id]);

  return { app, loading };
}
