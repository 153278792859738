type propTypes = {
  center?: boolean;
  children: React.ReactNode;
};

export default function Viewport(props: propTypes) {
  const className = props.center
    ? "uk-flex uk-flex-column uk-flex-middle uk-flex-center"
    : "";
  return (
    <div
      className={className}
      data-uk-height-viewport="expand: true; offset-top: true; offset-bottom: true"
    >
      <div>{props.children}</div>
    </div>
  );
}
