import { A, Info, Margin } from "../shared";
import { SubHeading } from "./Shared";
import screenshot from "./screenshot.png";

export default function Table() {
  return (
    <div>
      <Margin>
        <SubHeading>The Problem with Mobile App Stores</SubHeading>
      </Margin>

      <Margin>
        <table
          className="uk-table uk-table-divider uk-table-large uk-table-middle"
          style={{ fontSize: "1.2rem" }}
        >
          <thead>
            <tr className="uk-child-width-1-2">
              <th style={{ color: "red" }}>App Store & Google Play</th>
              <th style={{ color: "green" }}>PWA Store</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Up to 30% cuts per purchase + $300/year membership fees.</td>
              <td>
                <span style={{ marginRight: 4 }}>
                  No commissions + no fees = 100% revenue.
                </span>
                <Info
                  text="We only make money from donations and advertising on this
                  platform."
                ></Info>
              </td>
            </tr>
            <tr>
              <td>
                Applications, approvals, many rules and regulations. Easy to be
                rejected, especially small developers.
              </td>
              <td>
                <span style={{ marginRight: 4 }}>
                  No applications or approvals. We publish reviews & ratings and
                  leave judgement mostly to consumers.
                </span>
                <Info
                  text="We only ban apps & users performing harmful or illegal
                  activity such as scams, phishing, false advertising etc."
                ></Info>
              </td>
            </tr>
            <tr>
              <td>
                Users have to review and rate from the mobile app store with a
                registered account.
              </td>
              <td>
                <span style={{ marginRight: 4 }}>
                  Users can review and rate directly from your app without a PWA
                  Store account.
                </span>
                <Info text="Coming soon!"></Info>
              </td>
            </tr>
            <tr>
              <td>You have to submit your code to the mobile app stores.</td>
              <td>
                <span style={{ marginRight: 4 }}>
                  We never have access to your code.
                </span>
                <Info
                  text="You only install a code snippet that tracks your app's
                  installs."
                ></Info>
              </td>
            </tr>
            <tr>
              <td>Forced to wrap web app into native app.</td>
              <td>1st party publishing = publish PWA as is.</td>
            </tr>
            <tr>
              <td>Expensive to build apps in multiple platforms.</td>
              <td>Using 1 platform = the largest platform = web.</td>
            </tr>
            <tr>
              <td>
                Not visible, discoverable or sharable outside the mobile app
                store.
              </td>
              <td>SEO friendly, web discoverable & link sharable.</td>
            </tr>
            <tr>
              <td>Users have to install your app to use it.</td>
              <td>Users can interact with your app without installing.</td>
            </tr>
            <tr>
              <td>Most apps are designed for mobile phones only.</td>
              <td>PWAs work in mobile phones & desktops by default.</td>
            </tr>
          </tbody>
        </table>
      </Margin>

      <Margin>
        <div className="uk-flex uk-flex-center uk-text-center">
          <div
            className="uk-box-shadow-hover-small uk-padding-small"
            style={{ border: "1px solid #eee", borderRadius: 8, maxWidth: 640 }}
          >
            <A href="https://twitter.com/elonmusk/status/1597629846611726336">
              <img
                src={screenshot}
                alt="Elon Musk tweet about App Store and Play Store"
              />
            </A>
          </div>
        </div>
      </Margin>
    </div>
  );
}
