import { initializeApp, getApps } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { Auth, getAuth } from "firebase/auth";
import { Firestore, getFirestore } from "firebase/firestore";

const clientCredentials = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

let firebaseAuth: Auth;
let firebaseFirestore: Firestore;

if (getApps().length <= 0) {
  const app = initializeApp(clientCredentials);
  firebaseAuth = getAuth(app);
  firebaseFirestore = getFirestore(app);
  if (typeof window !== "undefined") {
    if ("measurementId" in clientCredentials) {
      getAnalytics();
    }
  }
}

export { firebaseAuth, firebaseFirestore };
