import { Link } from "react-router-dom";

type propTypes = {
  to: string;
  variant: "link" | "default" | "primary";
  size?: "small" | "large";
  children: React.ReactNode;
};

export default function L(props: propTypes) {
  let className = `uk-button uk-button-${props.variant}`;
  if (props.size) className += ` uk-button-${props.size}`;
  return (
    <Link to={props.to} className={className}>
      {props.children}
    </Link>
  );
}
