import { Section } from "../../components/shared";
import { Introduction, Table, FollowUp, FAQS } from "../../components/home";
import { Stars } from "../../components/home/Shared";

export default function Home() {
  return (
    <div data-uk-scrollspy="target: .uk-section; cls: uk-animation-fade; delay: 200">
      <Stars>
        <Section variant="primary" light stars>
          <Introduction />
        </Section>
      </Stars>
      <Section variant="default">
        <Table />
      </Section>
      <Section variant="muted">
        <FollowUp />
      </Section>
      <Section variant="default">
        <FAQS />
      </Section>
    </div>
  );
}
