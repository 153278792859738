type propTypes = {
  icon: string;
  variant?: "link" | "button";
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
};

export default function Icon(props: propTypes) {
  const className = props.variant ? `uk-icon-${props.variant}` : "";
  return (
    <span
      data-uk-icon={props.icon}
      className={className}
      onClick={props.onClick}
    />
  );
}
