import { useEffect, useState } from "react";
import { appsList, appType } from "../lib";

export default function useAppsList(uid: string) {
  const [apps, setApps] = useState<appType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (uid) {
      const unsubscribe = appsList(uid, (apps) => {
        setApps(apps);
        setLoading(false);
      });
      return unsubscribe;
    } else {
      setApps([]);
      setLoading(false);
      return undefined;
    }
  }, [uid]);

  return { apps, loading };
}
