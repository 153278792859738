import {
  signOut,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { actionCodeSettings, emailKey } from "./constants";
import { firebaseAuth } from "./firebase";
import { prompt } from "./utils";

async function handler(callback: () => Promise<void>) {
  try {
    await callback();
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export function authValidLink() {
  return isSignInWithEmailLink(firebaseAuth, window.location.href);
}

export function authSignInStart(email: string) {
  return handler(async () => {
    await sendSignInLinkToEmail(firebaseAuth, email, actionCodeSettings);
    window.localStorage.setItem(emailKey, email);
  });
}

export function authSignInComplete() {
  return handler(async () => {
    let email = window.localStorage.getItem(emailKey);
    if (!email)
      email = await prompt("Please provide your email for confirmation", "");
    if (email) {
      await signInWithEmailLink(firebaseAuth, email, window.location.href);
      window.localStorage.removeItem(emailKey);
    } else {
      throw Error("no email");
    }
  });
}

export function authSignOut() {
  return handler(async () => {
    await signOut(firebaseAuth);
  });
}
