import { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  aboutPath,
  appPath,
  authPath,
  completePath,
  createPath,
  rootPath,
  supportPath,
  updatePath,
  useUser,
} from "../../lib";
import { Spinner } from "../shared";
import Home from "../../pages/static/Home";
import { Authorized, Unauthorized } from "./Authorize";

const Support = lazy(() => import("../../pages/static/Support"));
const About = lazy(() => import("../../pages/static/About"));

const Dashboard = lazy(() => import("../../pages/apps/Dashboard"));
const App = lazy(() => import("../../pages/apps/App"));
const Create = lazy(() => import("../../pages/apps/Create"));
const Update = lazy(() => import("../../pages/apps/Update"));

const Auth = lazy(() => import("../../pages/auth/Auth"));
const Complete = lazy(() => import("../../pages/auth/Complete"));

export default function Routes() {
  const { user } = useUser();

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        {/* public */}
        <Route path={supportPath}>
          <Support />
        </Route>
        <Route path={aboutPath}>
          <About />
        </Route>
        {/* unauthorized */}
        <Route path={authPath}>
          <Unauthorized Component={Auth} user={user} />
        </Route>
        <Route path={completePath}>
          <Unauthorized Component={Complete} user={user} />
        </Route>
        {/* authorized */}
        <Route path={appPath}>
          <Authorized Component={App} user={user} />
        </Route>
        <Route path={updatePath}>
          <Authorized Component={Update} user={user} />
        </Route>
        <Route path={createPath}>
          <Authorized Component={Create} user={user} />
        </Route>
        {/* index */}
        <Route exact path={rootPath}>
          {user ? <Dashboard /> : <Home />}
        </Route>
        {/* fallback */}
        <Route path="*">
          <Redirect to={rootPath} />
        </Route>
      </Switch>
    </Suspense>
  );
}
