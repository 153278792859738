import { useMedia } from "../../hooks";
import { Grid, Margin, Text } from "../shared";
import Left from "./Left";
import Middle from "./Middle";
import Right from "./Right";

export default function Footer() {
  const matches = useMedia("(min-width: 640px)");
  const year = new Date().getFullYear();

  return (
    <div>
      <Margin>
        <Text variant="small">
          <Grid vertical="top" {...(matches && { horizontal: "around" })}>
            <div>
              <Middle />
            </div>
            <div className="uk-flex-first@m">
              <Left />
            </div>
            <div>
              <Right />
            </div>
          </Grid>
        </Text>
      </Margin>

      <Margin>
        <Text variant="center">
          <Text variant="meta">
            Copyright © {year} PWA Store. All rights reserved.
          </Text>
        </Text>
      </Margin>
    </div>
  );
}
