type propTypes = {
  variant:
    | "left"
    | "center"
    | "right"
    | "meta"
    | "bold"
    | "uppercase"
    | "danger"
    | "muted"
    | "small"
    | "large";
  children: React.ReactNode;
};

export default function Text(props: propTypes) {
  const className = `uk-text-${props.variant}`;
  return <div className={className}>{props.children}</div>;
}
