type propTypes = {
  label: string;
  meta?: string;
  required?: boolean;
  children: React.ReactNode;
};

export default function Control(props: propTypes) {
  return (
    <div>
      <label>
        <span className="uk-form-label">{props.label}</span>
        {props.required && <span className="uk-text-danger">* </span>}
        {props.meta && <span className="uk-text-meta">({props.meta})</span>}
      </label>
      <div className="uk-form-controls">{props.children}</div>
    </div>
  );
}
