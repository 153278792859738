type propTypes = {
  variant: "primary" | "secondary" | "muted" | "default";
  small?: boolean;
  light?: boolean;
  navbar?: boolean;
  stars?: boolean;
  children: React.ReactNode;
};

export default function Section(props: propTypes) {
  let className = `uk-section uk-section-${props.variant}`;
  if (props.small) className += " uk-section-small";
  if (props.light) className += " uk-light";
  if (props.navbar) className += " uk-padding-remove-vertical";
  const style = props.stars ? { background: "inherit" } : undefined;
  return (
    <div className={className} style={style}>
      <div className="uk-container">{props.children}</div>
    </div>
  );
}
