type propTypes = {
  href: string;
  variant?: "link" | "default" | "primary";
  size?: "small" | "large";
  children: React.ReactNode;
};

export default function A(props: propTypes) {
  let className = props.variant ? `uk-button uk-button-${props.variant}` : "";
  if (props.size) className += ` uk-button-${props.size}`;
  return (
    <a
      className={className}
      href={props.href}
      target="_blank"
      rel="noreferrer noopener"
    >
      {props.children}
    </a>
  );
}
