import { A, Margin } from "../shared";
import { SubHeading } from "./Shared";

export default function FAQS() {
  return (
    <div>
      <Margin>
        <SubHeading>Frequently Asked Questions</SubHeading>
      </Margin>

      <Margin>
        <ul data-uk-accordion="multiple: true">
          <li className="uk-open">
            <a className="uk-accordion-title" href="#1">
              What is a PWA?
            </a>
            <div className="uk-accordion-content">
              <span style={{ marginRight: 4 }}>
                A Progressive Web App is an installable website that works and
                feels just like a native (mobile/desktop) app.
              </span>
              <A href="https://web.dev/progressive-web-apps" variant="link">
                learn more
              </A>
            </div>
          </li>
          <li>
            <a className="uk-accordion-title" href="#2">
              How to convert a website to a PWA?
            </a>
            <div className="uk-accordion-content">
              <span style={{ marginRight: 4 }}>
                You can easily convert a website or web app into a PWA by adding
                a manifest file and a service worker.
              </span>
              <A href="https://youtu.be/sFsRylCQblw" variant="link">
                learn more
              </A>
            </div>
          </li>
        </ul>
      </Margin>
    </div>
  );
}
