// environment
export const currentDomain =
  process.env.NODE_ENV === "production"
    ? "https://www.pwastore.dev"
    : "http://localhost:3000";
export const logoPath = process.env.PUBLIC_URL + "/logo192.png";
export const subscribeURL =
  "https://dev.us10.list-manage.com/subscribe/post?u=ba7de01cc6505847c925f64d1&id=ce6721fefa";
export const emailSender = "noreply@pwastore.dev";

// url
export const rootPath = "/";
export const authPath = "/auth";
export const completePath = "/complete";
export const createPath = "/create";
export const supportPath = "/support";
export const aboutPath = "/about";
export const appPath = "/apps/:id";
export const getAppPath = (id: string) => `/apps/${id}`;
export const getUpdatePath = (id: string) => `/update/${id}`;
export const updatePath = "/update/:id";

// general
export const appsCollection = "apps";
export const pwaTest = "pwastore-test";
export const emailKey = "emailForSignIn";
export const actionCodeSettings = {
  url: `${currentDomain}${completePath}`,
  handleCodeInApp: true,
};

// form
export const inputMin = 3;
export const inputMax = 256;
