import L from "./L";
import Margin from "./Margin";
import Section from "./Section";
import Text from "./Text";
import Title from "./Title";
import Viewport from "./Viewport";

type propTypes = {
  title: string;
  path?: string;
  action?: string;
  children?: React.ReactNode;
};

export default function Message(props: propTypes) {
  return (
    <Section variant="muted">
      <Viewport center>
        <Text variant="center">
          <Margin>
            <Title center>{props.title}</Title>
          </Margin>
          <Margin>
            {props.action && props.path && (
              <L to={props.path} variant="primary">
                {props.action}
              </L>
            )}
            {props.children && props.children}
          </Margin>
        </Text>
      </Viewport>
    </Section>
  );
}
