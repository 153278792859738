import UIkit from "uikit";
import { Timestamp } from "firebase/firestore";
import { appType } from "./apps";
import { userType } from "./user";
import { inputMax, inputMin, pwaTest } from "./constants";

// validator

export function validateText(text: string) {
  return text.length >= inputMin && text.length <= inputMax;
}

export function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}

export function validateURL(string: string) {
  let url;
  try {
    url = new URL(string.toLowerCase());
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

// ui

export function notify(message: string) {
  UIkit.notification({
    message,
    status: "primary",
    pos: "bottom-left",
    timeout: 6000,
  });
}

export function prompt(content: string, value: string) {
  return UIkit.modal.prompt(content, value, { stack: true });
}

export async function confirm(message: string) {
  try {
    await UIkit.modal.confirm(message, { stack: true });
    return true;
  } catch (error) {
    return false;
  }
}

export const activeClass = (active: boolean) => (active ? "uk-active" : "");

// predicate

export function isEditor(user: userType, app: appType) {
  return user.uid === app.user.uid;
}

// factory

export const getDate = (timestamp?: Timestamp) =>
  timestamp?.toDate().toLocaleDateString() || "";

export const getTime = (timestamp?: Timestamp) =>
  timestamp?.toDate().toLocaleTimeString() || "";

export const getDateTime = (timeStamp?: Timestamp) =>
  timeStamp?.toDate().toLocaleString() || "";

export const verifyLink = (app: appType) => `${app.domain}?${pwaTest}=true`;

export const getCode = (id: string) => `<script type="module"
id="pwastore"
data-app-id="${id}"
src="https://script-pwa-store.vercel.app/index.js"
defer
></script>`;
