import {
  onSnapshot,
  collection,
  query,
  where,
  doc,
  addDoc,
  serverTimestamp,
  updateDoc,
  deleteDoc,
  orderBy,
  limit,
  Timestamp,
} from "firebase/firestore";
import {
  appsCollection,
  docType,
  wrapDoc,
  userType,
  wrapDocs,
  firebaseFirestore,
} from ".";

export interface appType extends docType {
  name: string;
  domain: string;
  user: userType;
  installs?: number;
  lastInstall?: Timestamp;
  lastVerified?: Timestamp;
}

const refApps = () => collection(firebaseFirestore, appsCollection);
const refApp = (id: string) => doc(firebaseFirestore, appsCollection, id);

export function appsList(uid: string, callback: (docs: appType[]) => void) {
  const ref = refApps();
  const q = query(
    ref,
    where("user.uid", "==", uid),
    orderBy("created", "desc"),
    limit(10)
  );
  const unsubscribe = onSnapshot(q, (snapshot) => {
    callback(wrapDocs(snapshot));
  });
  return unsubscribe;
}

export function appsGet(id: string, callback: (doc: appType | null) => void) {
  const ref = refApp(id);
  const unsubscribe = onSnapshot(ref, (snapshot) => {
    callback(wrapDoc(snapshot));
  });
  return unsubscribe;
}

export async function appsCreate(
  user: userType,
  data: Pick<appType, "name" | "domain">
) {
  const ref = refApps();
  const docRef = await addDoc(ref, {
    ...data,
    user,
    created: serverTimestamp(),
  });
  return docRef.id;
}

export async function appsUpdate(id: string, data: Pick<appType, "name">) {
  const ref = refApp(id);
  await updateDoc(ref, { ...data, updated: serverTimestamp() });
}

export async function appsDelete(id: string) {
  const ref = refApp(id);
  await deleteDoc(ref);
}
