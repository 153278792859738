import { Component, ErrorInfo } from "react";
import { Button, Message } from "./shared";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<Props, State> {
  public state: State = { hasError: false };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Message title="Something went wrong">
          <Button variant="primary" onClick={() => window.location.reload()}>
            reload
          </Button>
        </Message>
      );
    }

    return this.props.children;
  }
}
