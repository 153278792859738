import { A, Icon } from "../shared";

export default function Left() {
  return (
    <ul className="uk-nav uk-nav-default footer">
      <li className="uk-nav-header">Contact Us</li>
      <li>
        <span style={{ marginRight: 4 }}>
          <Icon icon="location" />
        </span>
        <A href="https://goo.gl/maps/PDLZK1RCXyKJWQtc6">5 Parvis Alan Turing</A>
      </li>
      <li>
        <span style={{ marginRight: 4 }}>
          <Icon icon="mail" />
        </span>
        <A href="mailto:hello@pwastore.dev">hello@pwastore.dev</A>
      </li>
      <li>
        <A href="https://www.patreon.com/thepwastore" variant="link">
          PATREON
        </A>
      </li>
      <li>
        <A href="https://www.buymeacoffee.com/pwastore" variant="link">
          BUY ME A COFFEE
        </A>
      </li>
      <li className="uk-flex uk-flex-between">
        <A href="https://github.com/The-PWA-Store" variant="link">
          github
        </A>
        <A
          href="https://www.clickpoll.app/polls/SiAPAqAXQ2GKyaeB2daL"
          variant="link"
        >
          vote!
        </A>
      </li>
    </ul>
  );
}
