import { Info, Margin, Text } from "../shared";
import { Actions, SubHeading } from "./Shared";

export default function FollowUp() {
  return (
    <Text variant="center">
      <Margin>
        <SubHeading>
          Do you really need the App Store or the Play Store?
        </SubHeading>
      </Margin>

      <Margin>
        <ul className="uk-list uk-list-large uk-h3">
          <li>The web is now capable and open.</li>
          <li>
            85% of mobile apps can work fine on the web.{" "}
            <Info text="Out of 200 randomly selected mobile apps, over 85% were fully implementable via web technologies & APIs available in 2022."></Info>
          </li>
          <li>PWAs are supported by all major browsers and devices today.</li>
        </ul>
      </Margin>

      <Margin>
        <Actions />
      </Margin>
    </Text>
  );
}
