import { useState } from "react";
import { validateText, validateURL } from "../lib";
import { appType } from "./../lib";

export default function useAppWrite(app: appType | null) {
  const [name, setName] = useState(app?.name || "");
  const [domain, setDomain] = useState(app?.domain || "");
  const [processing, setProcessing] = useState(false);
  const change = app ? app.name !== name || app.domain !== domain : true;
  const valid = validateText(name) && validateURL(domain) && change;
  return { name, setName, domain, setDomain, processing, setProcessing, valid };
}
