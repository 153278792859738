import { Link } from "react-router-dom";
import { Grid, Info, L, Margin, Text } from "../shared";
import { logoPath, rootPath, authPath } from "../../lib";
import { Actions, Heading } from "./Shared";

export default function Introduction() {
  return (
    <Text variant="center">
      <Margin>
        <Grid vertical="middle" horizontal="between">
          <div>
            <Link to={rootPath}>
              <img src={logoPath} alt="logo" width="56" height="56" />
            </Link>
          </div>
          <div>
            <L to={authPath} variant="default">
              sign in
            </L>
          </div>
        </Grid>
      </Margin>

      <Margin>
        <Heading>PWA Store</Heading>
        <div>App Store for Progressive Web Apps (PWAs)</div>
      </Margin>

      <Margin>
        <ul className="uk-list uk-list-large uk-h3">
          <li>We track PWA installs and statistics.</li>
          <li>
            <span style={{ marginRight: 4 }}>
              We offer PWA discovery and categorization.
            </span>
            <Info text="Coming soon!"></Info>
          </li>
          <li>
            <span style={{ marginRight: 4 }}>
              We publish PWA reviews and ratings.
            </span>
            <Info text="Coming soon!"></Info>
          </li>
          <li>We are neither Apple nor Google.</li>
          <li>We are a non-profit organization.</li>
        </ul>
      </Margin>

      <Margin>
        <Actions large />
      </Margin>
    </Text>
  );
}
