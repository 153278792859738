import { useLocation } from "react-router-dom";
import { aboutPath, rootPath, supportPath, useUser } from "../../lib";
import { Spinner, Section } from "../shared";
import Footer from "../footer";
import Navbar from "./Navbar";

type propTypes = {
  children: React.ReactNode;
};

export default function Layout(props: propTypes) {
  const { pathname } = useLocation();
  const { user, loading } = useUser();
  const isHome = pathname === rootPath && !user;
  const showNavbar = !isHome;
  const showFooter = isHome || [aboutPath, supportPath].includes(pathname);

  if (loading) return <Spinner />;

  return (
    <>
      {showNavbar && (
        <Section variant="primary" light navbar>
          <Navbar />
        </Section>
      )}
      {props.children}
      {showFooter && (
        <Section variant="secondary" light small>
          <Footer />
        </Section>
      )}
    </>
  );
}
