import { Grid, L } from "../shared";
import { authPath, supportPath } from "../../lib";

export function SubHeading(props: { children: React.ReactNode }) {
  return (
    <h2 className="uk-heading-small uk-margin-remove uk-text-center">
      {props.children}
    </h2>
  );
}

export function Heading(props: { children: React.ReactNode }) {
  return (
    <h1 className="uk-heading-large uk-margin-remove uk-text-center">
      {props.children}
    </h1>
  );
}

export function Actions(props: { large?: boolean }) {
  return (
    <Grid horizontal="center">
      <div>
        <L
          to={supportPath}
          variant="primary"
          {...(props.large && { size: "large" })}
        >
          support project
        </L>
      </div>
      <div className="uk-flex-first@m">
        <L
          to={authPath}
          variant="default"
          {...(props.large && { size: "large" })}
        >
          list pwa
        </L>
      </div>
    </Grid>
  );
}

export function Stars(props: { children: React.ReactNode }) {
  return (
    <div className="stars-container">
      <div>{props.children}</div>
      <div className="stars1"></div>
      <div className="stars2"></div>
      <div className="stars3"></div>
    </div>
  );
}
