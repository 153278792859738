import MailChimp from "react-mailchimp-subscribe";
import { subscribeURL } from "../../lib";
import Subscribe from "./Subscribe";

export default function Middle() {
  return (
    <div>
      <ul className="uk-nav uk-nav-default">
        <li className="uk-nav-header">Stay updated</li>
        <li>
          <MailChimp
            url={subscribeURL}
            render={({ subscribe, status, message }) => (
              <Subscribe
                onValidated={subscribe}
                status={String(status || "")}
                message={String(message || "")}
              />
            )}
          />
        </li>
      </ul>
    </div>
  );
}
