type propTypes = {
  variant: "default" | "primary" | "text" | "danger";
  size?: "small" | "large";
  submit?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
};

export default function Button(props: propTypes) {
  let className = `uk-button uk-button-${props.variant}`;
  if (props.size) className += ` uk-button-${props.size}`;
  return (
    <button
      type={props.submit ? "submit" : "button"}
      className={className}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}
